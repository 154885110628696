import { ActionFunctionArgs, json, LoaderFunctionArgs, MetaFunction, redirect } from "@remix-run/node";
import { t } from "i18next";
import { Link } from "lucide-react";
import { useTypedActionData, useTypedLoaderData } from "remix-typedjson";
import { MetaTagsDto } from "~/application/dtos/seo/MetaTagsDto";
import Logo from "~/components/brand/Logo";
import { AuthLayout } from "~/components/layouts/AuthLayout";
import Login from "~/components/login/login";
import { getTranslations } from "~/locale/i18next.server";
import { LoginActionData } from "~/modules/users/components/LoginForm";
import AuthService from "~/modules/users/services/AuthService";
import { useRootData } from "~/utils/data/useRootData";
import { getPublicSubscriptionProducts, getSubscriptionPrices } from "~/utils/db/subscriptionProducts.db.server";
import { getTenant } from "~/utils/db/tenants.db.server";
import { getUser } from "~/utils/db/users.db.server";
import { getUserInfo } from "~/utils/session.server";

export const meta: MetaFunction<typeof loader> = ({ data }) => data?.metatags || [];

type LoaderData = {
  metatags: MetaTagsDto;
  demoCredentials?: { email: string; password: string };
};
export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { t } = await getTranslations(request);

  const userInfo = await getUserInfo(request);
  if (userInfo.userId !== undefined && userInfo.userId !== "") {
    const user = await getUser(userInfo.userId);
    if (user) {
      if (!user?.defaultTenantId) {
        return redirect("/app");
      } else {
        const tenant = await getTenant(user.defaultTenantId);
        if (tenant) {
          return redirect(`/app/${tenant?.slug ?? tenant.id}`);
        }
      }
    }
  }

  const subProducts = await getPublicSubscriptionProducts();
  const subProdIds = subProducts.map((sP) => sP.id);
  const subPrices = await getSubscriptionPrices();
  const pricesWithTrial = subPrices
    .filter((price) => price.trialDays > 0 && price.active === true && subProdIds.includes(price.subscriptionProductId))
    .map((sP) => ({ id: sP.subscriptionProductId, trialDays: sP.trialDays }));

  return json(
    {
      title: `${t("account.login.title")} | ${process.env.APP_NAME}`,
      i18n: translations,
      pricesWithTrial,
    },
    {
      headers: {
        "Cache-Control": "max-age=30, s-maxage=60, stale-while-revalidate",
      },
    }
  );
};

type ActionData = {
  error?: string;
  fieldErrors?: {
    email: string | undefined;
    password: string | undefined;
  };
  fields?: {
    email: string;
    password: string;
  };
};

// export const action2: ActionFunction = async ({ request }) => {
//   let { t } = await getTranslations(request);
//   const cloneRequest = request.clone();
//   const userInfo = await getUserInfo(request);

//   const form = await request.formData();
//   const email = form.get("email")?.toString().toLowerCase().trim();
//   const password = form.get("new-password") ?? form.get("current-password");
//   const redirectTo = form.get("redirectTo");
//   //turn the formdata into an object
//   if (typeof email !== "string" || typeof password !== "string" || typeof redirectTo !== "string") {
//     return json({ error: t("shared.invalidForm") }, { status: 400 });
//   }

//   const fields = { email, password };
//   const fieldErrors = {
//     email: !UserUtils.validateEmail(email) ? "Invalid email" : undefined,
//     password: !UserUtils.validatePassword(password) ? "Invalid password" : undefined,
//   };
//   if (Object.values(fieldErrors).some(Boolean)) return json({ fieldErrors, fields }, { status: 400 });

//   let user = await getUserByEmail(email);
//   if (!user) {
//     //let's create the user
//     //TODO: need to fix the CSRF token validation
//     // await validateCSRFToken(cloneRequest);
//     const registrationData = await getRegistrationFormData(cloneRequest);
//     const result = await validateRegistration(request, registrationData);
//     if (result.registered) {
//       user = await getUserByEmail(email);
//     }
//   }

//   const isCorrectPassword = await bcrypt.compare(password, user.passwordHash);
//   if (!isCorrectPassword) {
//     return json({ fields, error: t("api.errors.invalidPassword") }, { status: 400 });
//   }

//   await createLogLogin(request, user);
//   const userSession = await setLoggedUser(user);
//   const tenant = await getTenant(userSession.defaultTenantId);

//   const subscriptions = await getActiveTenantSubscriptions(tenant?.id);

//   const redirect =
//     user.admin !== null ? "/admin/dashboard" : subscriptions?.products.length > 0 ? `/app/${tenant?.slug}/dashboard` : redirectTo.length > 0 ? redirectTo : "";

//   return createUserSession(
//     {
//       ...userInfo,
//       ...userSession,
//       lng: user.locale ?? userInfo.lng,
//     },
//     redirect
//   );
// };

export const action = async ({ request }: ActionFunctionArgs) => {
  const form = await request.formData();

  return await AuthService.loginFromRequest(request, form);

};

export default function LoginRoute() {
  const { appConfiguration } = useRootData();
  const data = useTypedLoaderData<LoaderData>();
  const actionData = useTypedActionData<LoginActionData>();

  return (
    <AuthLayout>
      <div>
        <Logo className="h-12 w-auto" />
        <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">Sign in to your account</h2>
        {/* <!-- TRIAL: START --> */}
        {/* TODO if a pricing plan with Trial Period Days */}

        <p className="mt-2 text-sm text-gray-600">
          Or{" "}
          <Link to={numTrials === 1 ? "/register" : "/pricing"} className="font-medium text-theme-600 hover:text-theme-500 dark:text-theme-400">
            {numTrials > 0 ? t("account.register.startTrial", [Math.min(...pricesWithTrial.map((p) => p.trialDays))]) : t("account.register.viewPricing")}
          </Link>
        </p>

        {/* <!-- TRIAL: END --> */}
      </div>

      <Login />
    </AuthLayout>
  );
}
